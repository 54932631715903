var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('EditorHeader'), !_vm.isLoading ? _c('Spinner', {
    attrs: {
      "propsOverlay": true
    }
  }) : _vm._e(), _c('div', {
    staticClass: "resizable-container"
  }, [_c('VueResizable', {
    ref: "resizableComponent",
    staticClass: "DAV-workshop",
    class: {
      'DAV-workshop__mobile': _vm.resize.height <= 56
    },
    attrs: {
      "active": _vm.resize.handlers,
      "disableAttributes": _vm.resize.disableAttributes,
      "fit-parent": _vm.resize.fit,
      "top": _vm.resize.top,
      "height": _vm.resize.height,
      "min-height": _vm.resize.minHeight,
      "max-height": _vm.resize.maxHeight
    },
    on: {
      "mount": _vm.eventHandler,
      "resize:move": _vm.eventHandler,
      "resize:start": _vm.eventHandler,
      "resize:end": _vm.eventHandler
    }
  }, [_c('ComponentTop', {
    attrs: {
      "propsWorkshopPosition": _vm.isWorkshopPosition
    },
    on: {
      "passPanel": _vm.passPanel,
      "resizeWorkshop": _vm.setWorkshopPosition
    }
  }), _vm.isLoading && _vm.getPanel === 'ComponentList' ? _c('ComponentList') : _vm._e(), _vm.isLoading && _vm.getPanel === 'ComponentOption' ? _c('ComponentOption') : _vm._e(), _vm.getPopup.page === 'ComponentImage' ? _c('ComponentImage', {
    ref: "componentImage"
  }) : _vm._e(), _vm.getPopup.page === 'ComponentIcon' ? _c('ComponentIcon', {
    ref: "componentIcon"
  }) : _vm._e(), _vm.getPanel === 'EditableLayout' ? _c('EditableLayout') : _vm._e(), _vm.getPopup.page === 'ComponentMultiText' ? _c('ComponentMultiText', {
    ref: "componentMultiText"
  }) : _vm._e(), _vm.getPopup.page === 'ComponentMultiTextURL' ? _c('ComponentMultiTextURL', {
    ref: "componentMultiTextURL"
  }) : _vm._e(), _vm.getPopup.page === 'ComponentMultiImage' ? _c('ComponentMultiImage', {
    ref: "componentMultiImage"
  }) : _vm._e(), _vm.getPopup.page === 'ComponentMultiImageURL' ? _c('ComponentMultiImageURL', {
    ref: "componentMultiImageURL"
  }) : _vm._e(), _vm.getPopup.page === 'ComponentMultiTextImageURL' ? _c('ComponentMultiTextImageURL', {
    ref: "componentMultiTextImageURL"
  }) : _vm._e(), _vm.getPopup.page === 'ComponentProductAdd' ? _c('ComponentProductAdd', {
    ref: "componentProductAdd"
  }) : _vm._e(), _vm.getPopup.page === 'ComponentProductList' ? _c('ComponentProductList', {
    ref: "componentProductList"
  }) : _vm._e()], 1)], 1), _vm.getPanel === 'ComponentClass' ? _c('ComponentClass') : _vm._e(), _vm.getPanel === 'ComponentAdd' ? _c('ComponentAdd') : _vm._e(), _vm.getPopup.page === 'HistoryPreview' ? _c('HistoryPreview') : _vm._e(), _vm.getPopup.page === 'ComponentPreview' ? _c('ComponentPreview') : _vm._e(), _vm.getPanel === 'EditablePage' ? _c('EditablePage') : _vm._e(), _vm.getPanel === 'SaveHistory' ? _c('SaveHistory') : _vm._e(), _vm.isLoading ? _c('EditorPreview') : _vm._e(), _vm.getPopup.page === 'ThemePreview' ? _c('ThemePreview') : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }