<template>
  <div>
    <!-- 에디터 상단 -->
    <EditorHeader />
    <!-- //에디터 상단 -->

    <!-- 에디터 스피너 -->
    <Spinner v-if="!isLoading" :propsOverlay="true" />
    <!-- //에디터 스피너 -->

    <div class="resizable-container">
      <VueResizable
        class="DAV-workshop"
        ref="resizableComponent"
        :active="resize.handlers"
        :disableAttributes="resize.disableAttributes"
        :fit-parent="resize.fit"
        :top="resize.top"
        :height="resize.height"
        :min-height="resize.minHeight"
        :max-height="resize.maxHeight"
        @mount="eventHandler"
        @resize:move="eventHandler"
        @resize:start="eventHandler"
        @resize:end="eventHandler"
        :class="{'DAV-workshop__mobile': resize.height <= 56}"
      >
        <!-- 컴포넌트 상단 -->
        <ComponentTop
          :propsWorkshopPosition="isWorkshopPosition"
          @passPanel="passPanel"
          @resizeWorkshop="setWorkshopPosition"
        />
        <!-- //컴포넌트 상단 -->

        <!-- 컴포넌트 목록 -->
        <ComponentList v-if="isLoading && getPanel === 'ComponentList'" />
        <!-- //컴포넌트 목록 -->

        <!-- 컴포넌트 옵션 -->
        <ComponentOption v-if="isLoading && getPanel === 'ComponentOption'" />
        <!-- //컴포넌트 옵션 -->

        <!-- 이미지 선택 -->
        <ComponentImage
          ref="componentImage"
          v-if="getPopup.page === 'ComponentImage'"
        />
        <!-- //이미지 선택 -->

        <!-- 아이콘 선택 -->
        <ComponentIcon
          ref="componentIcon"
          v-if="getPopup.page === 'ComponentIcon'"
        />
        <!-- //아이콘 선택 -->

        <!-- 페이지 레이아웃 설정 -->
        <EditableLayout v-if="getPanel === 'EditableLayout'" />
        <!-- //페이지 레이아웃 설정 -->

        <!-- multi text info -->
        <ComponentMultiText
          ref="componentMultiText"
          v-if="getPopup.page === 'ComponentMultiText'"
        />
        <!-- //multi text info -->

        <!-- multi text url info -->
        <ComponentMultiTextURL
          ref="componentMultiTextURL"
          v-if="getPopup.page === 'ComponentMultiTextURL'"
        />
        <!-- //multi text url info -->

        <!-- multi image info -->
        <ComponentMultiImage
          ref="componentMultiImage"
          v-if="getPopup.page === 'ComponentMultiImage'"
        />
        <!-- //multi image info -->

        <!-- multi image url info -->
        <ComponentMultiImageURL
          ref="componentMultiImageURL"
          v-if="getPopup.page === 'ComponentMultiImageURL'"
        />

        <!-- multi text image url info -->
        <ComponentMultiTextImageURL
          ref="componentMultiTextImageURL"
          v-if="getPopup.page === 'ComponentMultiTextImageURL'"
        />

        <!-- product add -->
        <ComponentProductAdd
          ref="componentProductAdd"
          v-if="getPopup.page === 'ComponentProductAdd'"
        />

        <!-- product list -->
        <ComponentProductList
          ref="componentProductList"
          v-if="getPopup.page === 'ComponentProductList'"
        />
        <!-- //multi image url info -->
      </VueResizable>
    </div>

    <!-- 컴포넌트 분류 -->
    <ComponentClass v-if="getPanel === 'ComponentClass'" />
    <!-- //컴포넌트 분류 -->

    <!-- 컴포넌트 추가 -->
    <ComponentAdd v-if="getPanel === 'ComponentAdd'" />
    <!-- //컴포넌트 추가 -->

    <!-- 테마 미리보기 -->
    <HistoryPreview v-if="getPopup.page === 'HistoryPreview'" />
    <!-- //테마 미리보기 -->

    <!-- 컴포넌트 미리보기 -->
    <ComponentPreview v-if="getPopup.page === 'ComponentPreview'" />
    <!-- //컴포넌트 미리보기 -->

    <!-- 페이지 선택 -->
    <EditablePage v-if="getPanel === 'EditablePage'" />
    <!-- //페이지 선택 -->

    <!-- 테마 되돌리기 -->
    <SaveHistory v-if="getPanel === 'SaveHistory'" />
    <!-- //테마 되돌리기 -->

    <!-- 미리보기 -->
    <EditorPreview v-if="isLoading" />
    <!-- //미리보기 -->

    <!-- 에디터 최근 저장 테마 미리보기 -->
    <ThemePreview v-if="getPopup.page === 'ThemePreview'" />
    <!-- //에디터 최근 저장 테마 미리보기 -->
  </div>
</template>

<script>
import Vue from "vue";
import VueMasonry from "vue-masonry-css";
import VueResizable from "vue-resizable";
import {createNamespacedHelpers} from "vuex";
import channelService from "@/utils/channel.js";

const ModuleTheme = createNamespacedHelpers("ModuleTheme");
const ModuleEditor = createNamespacedHelpers("ModuleEditor");
const ModuleAlert = createNamespacedHelpers("ModuleAlert");
const ModuleConfirm = createNamespacedHelpers("ModuleConfirm");
const ModuleIntroCommon = createNamespacedHelpers("ModuleIntroCommon");

Vue.use(VueMasonry);

// let parentHeight =
//   document.documentElement.scrollHeight < 600
//     ? 600
//     : document.documentElement.scrollHeight;
let innerHeight = window.innerHeight;
const downSpace = 56;
const upSpace = downSpace + 8;

export default {
  name: "editor",
  data() {
    return {
      isLoading: false,
      isWorkshopPosition: "center",
      resize: {
        fit: true,
        handlers: ["t"],
        disableAttributes: ["w", "l"],
        top: (innerHeight * 50) / 100,
        height: (innerHeight * 50) / 100,
        minHeight: downSpace,
        maxHeight: innerHeight - upSpace
      }
    };
  },
  watch: {
    // 테마 통합 불러오기
    getThemeLoadData() {
      this.isLoading = true;
      this.resizeHandler();
    }
  },
  computed: {
    ...ModuleTheme.mapGetters(["getThemeLoadData"]),
    ...ModuleEditor.mapGetters([
      "getThemeId",
      "getPopup",
      "getPanel",
      "getAttach"
    ]),
    ...ModuleIntroCommon.mapGetters(["getTemp"])
  },
  methods: {
    // 편집창 리사이즈: Component
    eventHandler(data) {
      // if (data.height >= innerHeight - upSpace) {
      //   return;
      // }
      // if (data.height <= downSpace) {
      //   return;
      // }
      this.resize.top = data.top;
      this.resize.height = data.height;

      if (data.eventName === "resize:end") {
        if (data.height <= downSpace) {
          this.isWorkshopPosition = "bottom";
        } else {
          this.isWorkshopPosition = "center";
        }
      }
    },
    // 편집창 리사이즈: Center
    setPositionCenter() {
      this.resize.top = (innerHeight * 50) / 100;
      this.resize.height = (innerHeight * 50) / 100;
    },
    // 편집창 리사이즈: Bottom
    setPositionBottom() {
      this.resize.top = (innerHeight * 100) / 100 - downSpace;
      this.resize.height = downSpace;
    },
    // 편집창 리사이즈: Emit Events
    setWorkshopPosition(workshopPosition) {
      if (workshopPosition === "bottom") {
        this.setPositionCenter();
        this.isWorkshopPosition = "center";
      }
      if (workshopPosition === "center") {
        this.setPositionBottom();
        this.isWorkshopPosition = "bottom";
      }
    },
    // 편집창 리사이즈: Document Resize
    resizeHandler() {
      innerHeight = window.innerHeight;
      this.resize.maxHeight = innerHeight - upSpace;
      if (this.isWorkshopPosition === "bottom") {
        this.setPositionBottom();
      }
      if (this.isWorkshopPosition === "center") {
        this.setPositionCenter();
      }
    },
    // 이전 페이지로 이동
    passPanel(page) {
      if (page === "image") {
        this.$refs.componentImage.goPrev();
      } else if (page === "icon") {
        this.$refs.componentIcon.goPrev();
      } else if (page === "multiText") {
        this.$refs.componentMultiText.goPrev();
      } else if (page === "multiTextURL") {
        this.$refs.componentMultiTextURL.goPrev();
      } else if (page === "multiImage") {
        this.$refs.componentMultiImage.goPrev();
      } else if (page === "multiImageURL") {
        this.$refs.componentMultiImageURL.goPrev();
      } else if (page === "multiTextImageURL") {
        this.$refs.componentMultiTextImageURL.goPrev();
      } else if (page === "productAdd") {
        this.$refs.componentProductAdd.goPrev();
      } else if (page === "productList") {
        this.$refs.componentProductList.goPrev();
      } else {
        if (this.isWorkshopPosition === "bottom") {
          this.setPositionCenter();
          this.isWorkshopPosition = "center";
        }
        this.setPanel("ComponentList");
      }
    },
    // 디바이스 타입
    deviceTypeInit() {
      let parentWidth = document.documentElement.clientWidth;
      let childWidth =
        parentWidth < 1024
          ? 0
          : document.querySelector(".DAV-workshop").clientWidth;
      let deviceType = parentWidth - childWidth < 1024 ? "mobile" : "desktop";
      this.setDeviceType(deviceType);
    },
    // 디바이스 체크
    isMobile() {
      return (
        navigator.maxTouchPoints || "ontouchstart" in document.documentElement
      );
    },
    // 테마 통합 불러오기
    themeLoad() {
      let payload = {};
      payload.themeId = this.getThemeId;
      if (this.$route.query.mode === "partner") {
        payload.mode = "partner";
        this.setMode("partner");
      }

      this.actThemeLoad(payload).then(response => {
        if (response.status === 403 || response.status === 404) {
          this.setAlert({
            isVisible: true,
            message: "접근 권한이 없습니다.",
            event: "back"
          });
        }
      });
    },
    // 솔루션 목록
    solutionList() {
      this.actSolutionList().then(() => {});
    },
    // // 이탈 방지
    // unLoadEvent(event) {
    //   if (this.getAttach) {
    //     event.preventDefault();
    //     event.returnValue = "";
    //   }
    // },

    ...ModuleTheme.mapActions(["actThemeLoad"]),
    ...ModuleEditor.mapMutations([
      "setPopup",
      "setAttach",
      "setThemeId",
      "setPanel",
      "setDeviceType",
      "setUserDevice",
      "setUserThemeHistoryId",
      "setMode"
    ]),
    ...ModuleAlert.mapMutations(["setAlert"]),
    ...ModuleConfirm.mapMutations(["setConfirm"]),
    ...ModuleEditor.mapActions(["actSolutionList"])
  },
  created() {
    channelService.constructor();
    this.$EventBus.$on("alertCancel", event => {
      if (event === "back") {
        this.$router.go(-1) || window.close();
      }
    });
  },
  mounted() {
    if (window.ChannelIO) channelService.shutdown();

    window.addEventListener("resize", this.resizeHandler);
    this.setThemeId(this.$route.params.themeId);
    this.setPanel("ComponentList");
    // 디바이스 타입
    this.deviceTypeInit();
    // 디바이스 체크
    this.setUserDevice(!this.isMobile() ? "desktop" : "mobile");
    // 테마 통합 불러오기
    this.themeLoad();
    // 솔루션 목록
    this.solutionList();
    this.setAttach(false);
    this.setPopup({page: null});
    this.setUserThemeHistoryId(null);
    // window.addEventListener("beforeunload", this.unLoadEvent);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeHandler);
    // window.removeEventListener("beforeunload", this.unLoadEvent);
  },
  beforeRouteLeave(to, from, next) {
    if (this.getTemp === 401) {
      next();
    } else if (this.getAttach) {
      next(false);
      this.setConfirm({
        isVisible: true,
        message: `테마를 저장해야 편집된 내용이 적용됩니다.`,
        messageBtn: `나가기`,
        event: () => {
          next();
        }
      });
    } else {
      next();
    }
  },
  components: {
    EditorHeader: () => import("@/components/editor/Header"),
    EditorPreview: () => import("@/components/editor/EditorPreview"),
    ComponentTop: () => import("@/components/editor/ComponentTop"),
    ComponentList: () => import("@/components/editor/ComponentList"),
    ComponentOption: () => import("@/components/editor/ComponentOption"),
    ComponentImage: () => import("@/components/editor/ComponentImage"),
    ComponentIcon: () => import("@/components/editor/ComponentIcon"),
    ComponentClass: () => import("@/components/editor/ComponentClass"),
    ComponentAdd: () => import("@/components/editor/ComponentAdd"),
    HistoryPreview: () => import("@/components/editor/HistoryPreview"),
    ComponentPreview: () => import("@/components/editor/ComponentPreview"),
    ComponentMultiText: () => import("@/components/editor/ComponentMultiText"),
    ComponentMultiTextURL: () =>
      import("@/components/editor/ComponentMultiTextURL"),
    ComponentMultiImage: () =>
      import("@/components/editor/ComponentMultiImage"),
    ComponentMultiImageURL: () =>
      import("@/components/editor/ComponentMultiImageURL"),
    ComponentMultiTextImageURL: () =>
      import("@/components/editor/ComponentMultiTextImageURL"),
    ComponentProductAdd: () =>
      import("@/components/editor/ComponentProductAdd"),
    ComponentProductList: () =>
      import("@/components/editor/ComponentProductList"),
    EditablePage: () => import("@/components/editor/EditablePage"),
    EditableLayout: () => import("@/components/editor/EditableLayout"),
    SaveHistory: () => import("@/components/editor/SaveHistory"),
    Spinner: () => import("@/components/basis/spinner"),
    ThemePreview: () => import("@/components/editor/ThemePreview"),
    VueResizable
  }
};
</script>

<style lang="scss">
body {
  overscroll-behavior: none;
  overflow: hidden;
}
#app {
  height: 100vh;
  height: -webkit-stretch;
  height: stretch;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
/* Spinner Custom */
// .common-spinner {
//   display: none !important;
// }
/* Resizable Custom */
.resizable-container {
  z-index: 1;
  position: fixed;
  top: 64px;
  bottom: 0;
  left: 0;
  width: 1px;

  .resizable-component {
    position: fixed !important;

    .resizable-t {
      top: 0;
      width: auto;
      height: 34px;
      left: 120px;
      right: 120px;
    }
  }
}
.DAV-workshop {
  z-index: 10;
  position: fixed;
  top: auto !important;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 16px 16px 0px 0px;
  background-color: #f7f9fc;
}
.DAV-workshop__space {
  z-index: 11;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: hidden;
  background-color: #f7f9fc;
}
.DAV-workshop__trunk {
  z-index: 1;
  position: absolute;
  top: 56px;
  right: 0;
  bottom: 0;
  left: 0;
}
.DAV-blockClass {
  z-index: 1;
  position: absolute;
  top: 56px;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  background-color: #f7f9fc;
}
.DAV-titleBar {
  position: relative;
  display: flex;
  align-items: center;
  padding: 20px 0 12px;
  border-bottom: 1px solid #f1f1f1;

  h4 {
    color: #606060;
    font-size: 16px;
    font-weight: 500;
  }
  &__help {
    display: flex;
    width: 24px;
    height: 24px;
    margin-left: 2px;
    background-image: url($path + "ico_help.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    text-indent: -9999px;
  }
  &__toggle {
    position: relative;
    width: 48px;
    height: 24px;
    margin-left: 8px;
    background: #dcdcdc;
    border-radius: 24px;
    text-indent: -9999px;

    span {
      position: absolute;
      top: 2px;
      left: 2px;
      background: #ffffff;
      box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08);
      border-radius: 20px;
      width: 20px;
      height: 20px;
    }
    &--active {
      background-color: #54c7a2;

      span {
        left: 26px;
      }
      span::before {
        position: absolute;
        top: 0;
        left: -19px;
        width: 12px;
        height: 100%;
        content: "";
        background-image: url($path + "bul_selected.svg");
        background-repeat: no-repeat;
        background-position: 0 50%;
        background-size: 12px auto;
      }
    }
  }
  &__device {
    width: 24px;
    height: 24px;
    margin-left: auto;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    text-indent: -9999px;

    &--mobile {
      background-image: url($path + "bg_device_mobile.svg");
    }
    &--pc {
      background-image: url($path + "bg_device_pc.svg");
    }
    &--union {
      background-image: url($path + "bg_device_union.svg");
    }
  }
}
.titleGuide {
  z-index: 2;
  display: block;
  position: absolute;
  top: 45px;
  right: 0;
  left: 0;
  padding: 12px 32px 12px 12px;
  background-color: #ffffff;
  border: 1px solid #d8dae5;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  &--close {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 18px;
    height: 18px;
    background-image: url($path + "ico_modal_close.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    text-indent: -9999px;
  }
  &__head {
    color: #484948;
    font-size: 14px;
  }
  &__list li {
    margin-top: 6px;
    margin-left: 16px;
    color: #484948;
    font-size: 12px;
    list-style-type: disc;
  }
  &__text {
    color: #484948;
    font-size: 12px;

    p {
      padding-left: 8px;
      text-indent: -8px;
      letter-spacing: -0.5px;
    }
    button {
      color: #3366ff;
    }
  }
}
.frmBlock__layout {
  position: relative;
  display: flex;
  margin-top: 16px;

  &--grow {
    .frmBlock__textField {
      flex: auto;
      width: 100%;
    }
    .frmBlock__dropdownField {
      margin-left: 16px;
      flex: none;
      width: 128px;
    }
    .frmBlock__selectField {
      margin-left: 16px;
      flex: none;
      width: 128px;
    }
  }
}
.frmBlock__selectField {
  height: 48px;
  padding: 0 16px;
  border: 1px solid #d8dae5;
  box-sizing: border-box;
  border-radius: 8px;
  outline: none;
  background-image: url($path + "bul_arr_down.svg");
  background-repeat: no-repeat;
  background-position: right 16px center;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
.frmBlock__display {
  display: flex;
  margin: 16px -8px 0;

  button {
    position: relative;
    flex: 1;
    height: 88px;
    margin: 0 8px;
    border: 1px solid #d8dae5;
    box-sizing: border-box;
    border-radius: 8px;
  }
  span {
    display: block;
    margin-top: 60px;
    color: #8d8d8d;
    font-size: 14px;
    text-align: center;
  }
  span::before {
    display: none;
    content: "selected";
    position: absolute;
    top: 8px;
    right: 8px;
    width: 16px;
    height: 16px;
    background-image: url($path + "bg_selected.svg");
    background-repeat: no-repeat;
    background-position: 0 0;
    text-indent: -9999px;
  }
}
.frmBlock__title {
  margin: 0 0 -8px;
  font-size: 14px;
  font-weight: 500;
  color: #484948;
  position: relative;

  &--last {
    margin-top: 22px;
  }
}
.deviceDisplay {
  &__union {
    background-image: url($path + "bul_device_union.svg");
    background-repeat: no-repeat;
    background-position: 50% 18px;

    &.deviceDisplay--active {
      background-image: url($path + "bul_device_union_on.svg");
    }
  }
  &__mobile {
    background-image: url($path + "bul_device_mobile.svg");
    background-repeat: no-repeat;
    background-position: 50% 18px;

    &.deviceDisplay--active {
      background-image: url($path + "bul_device_mobile_on.svg");
    }
  }
  &__pc {
    background-image: url($path + "bul_device_pc.svg");
    background-repeat: no-repeat;
    background-position: 50% 18px;

    &.deviceDisplay--active {
      background-image: url($path + "bul_device_pc_on.svg");
    }
  }
  &--active {
    background-color: #d2f8ec;
    border: 1px solid #54c7a2 !important;

    span {
      color: #54c7a2;
    }
    span::before {
      display: block;
    }
  }
}
.areaPosition {
  &__header {
    background-image: url($path + "ico_position_header.svg");
    background-repeat: no-repeat;
    background-position: 50% 14px;

    &.areaPosition--active {
      background-image: url($path + "ico_position_header_on.svg");
    }
  }
  &__content {
    background-image: url($path + "ico_position_content.svg");
    background-repeat: no-repeat;
    background-position: 50% 14px;

    &.areaPosition--active {
      background-image: url($path + "ico_position_content_on.svg");
    }
  }
  &__footer {
    background-image: url($path + "ico_position_footer.svg");
    background-repeat: no-repeat;
    background-position: 50% 14px;

    &.areaPosition--active {
      background-image: url($path + "ico_position_footer_on.svg");
    }
  }
  &--active {
    background-color: #d2f8ec;
    border: 1px solid #54c7a2 !important;

    span {
      color: #54c7a2;
    }
    span::before {
      display: block;
    }
  }
}
/* transition */
.fade {
  &-enter-active,
  &-leave-active {
    transition: 0.5s ease;
  }
  &-enter,
  &-leave-to {
    opacity: 0;
  }
}
.slide {
  &-enter-active,
  &-leave-active {
    transition: 0.5s ease;
  }
  &-enter,
  &-leave-to {
    transform: translate(100%, 0);
  }
}
.slideup {
  &-enter-active {
    transition: 0.3s ease-in;
  }
  &-leave-active {
    transition: 0.3s cubic-bezier(0, 1, 0.5, 1);
  }
  &-enter-to,
  &-leave {
    overflow: hidden;
    max-height: 300px;
  }
  &-enter,
  &-leave-to {
    overflow: hidden;
    max-height: 0;
  }
}
/* 반응형 - desktop */
@media screen and (min-width: 1024px) {
  .resizable-container {
    width: 421px;
    top: 0;
    left: auto;
    right: 0;
  }
  .DAV-workshop {
    width: 421px;
    height: auto !important;
    top: 0 !important;
    left: auto;
    right: 0;
  }
  .DAV-workshop__space {
    z-index: 10;
    top: 56px;
    left: auto;
    width: 421px;
  }
  .DAV-workshop__trunk {
    left: auto;
    width: 421px;
  }
}
/* 반응형 - mobile */
@media screen and (max-width: 1023px) {
  .DAV-workshop__trunk {
    overflow-y: auto;
  }
  .DAV-workshop__mobile {
    bottom: 0px !important;
    top: auto !important;
  }
  .DAV-blockClass {
    top: 0;
  }
}
</style>
